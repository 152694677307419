
  
  <script>
  export default {
    methods: {
      goHome() {
        this.$router.push({ name: 'Home' }); // Adjust the route name as needed
      }
    }
  };
  </script>
  
  <style scoped>
  .home-button {
    position: fixed; /* Ensure it's fixed */
    top: 20px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
    padding: 10px 15px;
    background-color: white; /* Bootstrap primary color */
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000; /* Ensure it stays on top */
  }
  
  .home-button:hover {
    background-color: #f0f0f0; /* Darker shade on hover */
  }
  </style>