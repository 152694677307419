// src/services/wordpressService.js
import axios from 'axios';

// Update the base URL to point to your WordPress blog
//const API_BASE_URL = 'http://localhost:8000/blog/wp-json/wp/v2'; // Adjust the path as needed
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  getPosts(params = {}) {
    return axios.get(`${API_BASE_URL}/posts`, { params });
  },
  getPost(id) {
    return axios.get(`${API_BASE_URL}/posts/${id}`);
  },
  // Add more methods as needed
};
